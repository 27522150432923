import React from 'react'
import { graphql } from 'gatsby'
import { LandingPageContextProps } from 'templates/layouts/LandingPageLayout'
import BlogLandingLayout from './layouts/BlogLandingLayout'

interface Props {
  data: Queries.BlogLandingQuery
  pageContext: LandingPageContextProps
}

const BlogLanding = ({ data, pageContext }: Props) => {
  const landing = data.datoCmsLandingPage
  const posts = data.allDatoCmsBlogPost.edges

  return (
    <BlogLandingLayout
      pageContext={pageContext}
      landing={landing}
      posts={posts}
    />
  )
}

export const pageQuery = graphql`
  query BlogLanding(
    $skip: Int!
    $perPage: Int!
    $originalId: String!
    $categoryOriginalId: String!
  ) {
    datoCmsLandingPage(originalId: { eq: $originalId }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsBlogPost(
      limit: $perPage
      skip: $skip
      sort: { fields: [isSeoPost, meta___createdAt], order: [DESC, DESC] }
      filter: {
        category: { elemMatch: { originalId: { eq: $categoryOriginalId } } }
      }
    ) {
      edges {
        node {
          ...cardBlogPost
        }
      }
    }
  }
`

export default BlogLanding
